<template>
    <v-card class="elevation-10">
      <v-tabs v-model="tab" background-color="primary">
        <v-tab href="#Geral">Geral</v-tab>
        <v-tab href="#Subscricoes" v-if="$root.session.hasPermission(['super'])" :disabled="method == 'create' ? true: false">Subscrições</v-tab>
        <v-tab href="#Acessos" v-if='this.$root.session.hasPermission(["super", "accesses.read"])' :disabled="method == 'create' ? true : false">Acessos</v-tab>
        <v-tab href="#Dados" :disabled="method == 'create' ? true : false">Dados Faturação</v-tab>
      </v-tabs>
      
      <v-card-text>
        <v-tabs-items v-model="tab">
          <v-tab-item value="Geral">
            <validation-observer ref="form" v-slot="{ invalid }">      
              <v-form @submit.prevent="submit"  class="mb-16">
                <v-progress-linear v-if="loading"
                    indeterminate
                    class="global-loader"
                  ></v-progress-linear>
                <fieldset :disabled="loading">
                  <v-row>
                    <v-col cols="12" class="mb-n10 mt-n4">
                      <v-switch
                          style="float: right; color: red;"
                          v-model="fields.is_active"
                          inset
                          color="success"
                          :label="fields.is_active ? 'Ativo' : 'Inativo'"
                      ></v-switch>
                    </v-col>     
                    <v-col cols="12" md="3">
                        <v-text-field
                          :disabled="method == 'create'"
                          v-model="fields.reference"
                          prepend-inner-icon="mdi-code-string"
                          label="Referência"
                          clearable
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <validation-provider v-slot="{ errors }" vid="name" name="Nome" rules="required">
                        <v-text-field
                          v-model="fields.name"
                          prepend-inner-icon="mdi-text-box"
                          label="Nome"
                          clearable
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3">
                      <validation-provider v-slot="{ errors }" vid="nif" name="Nif" rules="required">
                        <v-text-field
                          v-model="fields.nif"
                          prepend-inner-icon="mdi-barcode"
                          label="Nif"
                          clearable
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3">
                      <validation-provider v-slot="{ errors }" vid="telephone" name="Telefone" rules="required">
                        <v-text-field
                          v-model="fields.telephone"
                          prepend-inner-icon="mdi-phone"
                          label="Telefone"
                          clearable
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="4">
                      <validation-provider v-slot="{ errors }" vid="email" name="Email" rules="required">
                        <v-text-field
                          v-model="fields.email"
                          prepend-inner-icon="mdi-email"
                          label="Email"
                          clearable
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>            
                    <v-col cols="12" md="8">
                      <validation-provider v-slot="{ errors }" vid="address" name="Endereço" rules="required">
                        <v-text-field
                          v-model="fields.address"
                          prepend-inner-icon="mdi-map-marker"
                          label="Endereço"
                          clearable
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field
                          v-model="fields.dominio"
                          prepend-inner-icon="mdi-link"
                          label="Domínio"
                          clearable
                          :disabled="true"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="auto" v-if="fields.link != null">
                      <span class="font-weight-bold">Link de acesso:</span><span class="ml-4" style="cursor: pointer; text-decoration: underline;" @click="goTo(fields.link)">https://{{fields.link}}</span>
                    </v-col>
                    </v-row>
                  <v-row class="mt-6" align="center" justify="space-around">
                    <v-col align="center" justify="space-around">
                      <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                        Gravar
                      </v-btn>
                    </v-col>
                  </v-row>
                </fieldset>
              </v-form>
            </validation-observer>
          </v-tab-item>   

          <v-tab-item value="Subscricoes">
            <Subscriptions :tenant="fields.tenant" />
          </v-tab-item>
        
          <v-tab-item value="Acessos">
            <ListUsers :tenant="fields.tenant"/>
          </v-tab-item>

          <v-tab-item value="Dados">
            <InvoiceData :tenant="fields" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <DialogSuccess :opened="success" @on-ok="onSuccess" >
          Cliente gravado com sucesso!
      </DialogSuccess>
    </v-card>
</template>
<style>
.v-tab{
  color: white !important;
}
.v-tab--active{
  color: rgb(148, 143, 143) !important;
}
</style>
<script>

import ListUsers from '@/components/tenants/accesses/ListUsers.vue';
import InvoiceData from '@/components/tenants/invoice_data/InvoiceData.vue';
import Subscriptions from '@/components/tenants/subscriptions/Subscriptions.vue'
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import Tenant from "@/api/Tenant.js";

export default {
  components: {
    DialogSuccess,
    ListUsers,
    Subscriptions,
    InvoiceData
  },
  props:{
    method: String
  },
  watch: { 
    tenant: {
      immediate: true, 
      handler (val) {
        this.fields.theme = this.$themes[0];

        if(!val) {
          return;
        }

        let data = {...val};

        this.fields = data;



      }
    },
  },
  beforeCreate(){
    
  },
  data: () => ({
      subscription_types: [],
      subscription_history: [],
      tab: null,
      success: false, 
      loading: false,
      fields: {
        id: null,
        name: null,
        reference: null,
        nif: null,
        telephone: null,
        email: null, 
        address: null, 
        dominio: null,
        is_active: true
      }
  }),
  async mounted(){
    if(this.method == 'update'){
      await this.loadTenant()
  

      // Verificar se vem das notificações
      if(this.$route.params.invoice_id != undefined){
        this.tab = this.$route.params.place
      }
    }    
  },
  methods:{
    goTo(link){
      window.open('https://' + link, '_blank')
    },
    loadTenant(){
      Tenant.find(this.$route.params.id).then(({data}) => {
          this.fields = data
          this.fields.id = data.id
          this.fields.name = data.name
          this.fields.nif = data.nif
          this.fields.telephone = data.telephone
          this.fields.reference = data.reference
          this.fields.email = data.email
          this.fields.address = data.address
          this.fields.dominio = data.domain
          this.fields.tenant = data.tenant_id
          this.fields.is_active = data.is_active
          this.fields.link = data.link
      });
    },
    getSubType(sub){
      let name = ''
      this.subscription_types.forEach(element => {
        if(element.id == sub)
          name = element.type
      });
      return name
    },
    onSuccess() {
      if(this.fields.id != null) {
        this.success = false
        return
      }
      this.$router.push('/tenants');
    },
    submit() {
      this.$refs.form.validate().then((result) => {
        this.loading = true;
        
        if(!result){
          this.loading = false;
          return;
        }

        if(this.fields.renew == true){
          if(this.fields.subscription_start == null)
            return
          if(this.fields.subscription_end == null)
            return
          if(this.fields.subscription_id == null)
            return
        }

        Tenant[this.method](this.fields, this.fields.id).then(() => {

          this.success = true;

          this.loading = false;
          
        }).catch(err => {
          this.loading = false;

            if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);
              
              return;
            }

            this.error.title = "Erro " + err.response.status;

            this.error.message = err.response.data.message;

          });
      })
    }
  },
  computed: {
    
  }
};
</script>
  