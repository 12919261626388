var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-10"},[_c('v-tabs',{attrs:{"background-color":"primary"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"href":"#Geral"}},[_vm._v("Geral")]),(_vm.$root.session.hasPermission(['super']))?_c('v-tab',{attrs:{"href":"#Subscricoes","disabled":_vm.method == 'create' ? true: false}},[_vm._v("Subscrições")]):_vm._e(),(this.$root.session.hasPermission(["super", "accesses.read"]))?_c('v-tab',{attrs:{"href":"#Acessos","disabled":_vm.method == 'create' ? true : false}},[_vm._v("Acessos")]):_vm._e(),_c('v-tab',{attrs:{"href":"#Dados","disabled":_vm.method == 'create' ? true : false}},[_vm._v("Dados Faturação")])],1),_c('v-card-text',[_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":"Geral"}},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"mb-16",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[(_vm.loading)?_c('v-progress-linear',{staticClass:"global-loader",attrs:{"indeterminate":""}}):_vm._e(),_c('fieldset',{attrs:{"disabled":_vm.loading}},[_c('v-row',[_c('v-col',{staticClass:"mb-n10 mt-n4",attrs:{"cols":"12"}},[_c('v-switch',{staticStyle:{"float":"right","color":"red"},attrs:{"inset":"","color":"success","label":_vm.fields.is_active ? 'Ativo' : 'Inativo'},model:{value:(_vm.fields.is_active),callback:function ($$v) {_vm.$set(_vm.fields, "is_active", $$v)},expression:"fields.is_active"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"disabled":_vm.method == 'create',"prepend-inner-icon":"mdi-code-string","label":"Referência","clearable":""},model:{value:(_vm.fields.reference),callback:function ($$v) {_vm.$set(_vm.fields, "reference", $$v)},expression:"fields.reference"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"name","name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-text-box","label":"Nome","clearable":"","error-messages":errors},model:{value:(_vm.fields.name),callback:function ($$v) {_vm.$set(_vm.fields, "name", $$v)},expression:"fields.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"nif","name":"Nif","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-barcode","label":"Nif","clearable":"","error-messages":errors},model:{value:(_vm.fields.nif),callback:function ($$v) {_vm.$set(_vm.fields, "nif", $$v)},expression:"fields.nif"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"telephone","name":"Telefone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-phone","label":"Telefone","clearable":"","error-messages":errors},model:{value:(_vm.fields.telephone),callback:function ($$v) {_vm.$set(_vm.fields, "telephone", $$v)},expression:"fields.telephone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"email","name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-email","label":"Email","clearable":"","error-messages":errors},model:{value:(_vm.fields.email),callback:function ($$v) {_vm.$set(_vm.fields, "email", $$v)},expression:"fields.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('validation-provider',{attrs:{"vid":"address","name":"Endereço","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-map-marker","label":"Endereço","clearable":"","error-messages":errors},model:{value:(_vm.fields.address),callback:function ($$v) {_vm.$set(_vm.fields, "address", $$v)},expression:"fields.address"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-link","label":"Domínio","clearable":"","disabled":true},model:{value:(_vm.fields.dominio),callback:function ($$v) {_vm.$set(_vm.fields, "dominio", $$v)},expression:"fields.dominio"}})],1),(_vm.fields.link != null)?_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Link de acesso:")]),_c('span',{staticClass:"ml-4",staticStyle:{"cursor":"pointer","text-decoration":"underline"},on:{"click":function($event){return _vm.goTo(_vm.fields.link)}}},[_vm._v("https://"+_vm._s(_vm.fields.link))])]):_vm._e()],1),_c('v-row',{staticClass:"mt-6",attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{staticClass:"mr-5 ml-5 mb-5",attrs:{"disabled":invalid,"depressed":"","color":"primary","type":"submit"}},[_vm._v(" Gravar ")])],1)],1)],1)],1)]}}])})],1),_c('v-tab-item',{attrs:{"value":"Subscricoes"}},[_c('Subscriptions',{attrs:{"tenant":_vm.fields.tenant}})],1),_c('v-tab-item',{attrs:{"value":"Acessos"}},[_c('ListUsers',{attrs:{"tenant":_vm.fields.tenant}})],1),_c('v-tab-item',{attrs:{"value":"Dados"}},[_c('InvoiceData',{attrs:{"tenant":_vm.fields}})],1)],1)],1),_c('DialogSuccess',{attrs:{"opened":_vm.success},on:{"on-ok":_vm.onSuccess}},[_vm._v(" Cliente gravado com sucesso! ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }